import React from "react"
// import { Link } from "gatsby"


import Layout from "../components/layout"
import {Helmet} from 'react-helmet'






class About extends React.Component {
  render() {



    return  (
      <Layout searcharray={this.props.pageContext.searcharray} path={this.props.path} menu={this.props.pageContext.menu}>
        <Helmet>
          {this.props.pageContext.metainfo.map((item,i) => {
    if(item.key === 'title') {
      return (
        <title key={i}>{item.value}</title>
      )
    }
    else if(item.key === 'canonical') {
      return (
        <link key={i} rel="canonical" href={this.props.path} />
      )
    }

    else if(item.key === 'description') {
      return (
        <meta key={i} name="description" content={item.value}/>
      )
    }

    else {
      return null;
    }
  })}
        </Helmet>

          <section className="blogsection" >
            <h2 className="text-center">{this.props.pageContext.title}</h2>
            <div className="imagecont">
             <img className="w-100" src={this.props.pageContext.imagedetails.url} alt={this.props.pageContext.imagedetails.alt}/>
            </div>
            <div className="mt-3" dangerouslySetInnerHTML={{ __html: this.props.pageContext.body }}></div>






          </section>






      </Layout>

    )
  }
}



export default About
